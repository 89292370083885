<template>
  <div class="XueRenHeadline">
    <el-row type="flex" justify="center">
      <el-col :span="24">
        <div>
          <img src="@/assets/image/home/左.png" alt="" class="left" />
          <img  v-lazy="title" alt="" class="XueRenHeadline_img" @click="dship(num)"/>
          <img src="@/assets/image/home/右.png" alt="" class="right"/>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "",

  components: {},

  props: {
    title: {
      type: String,
      required: true,
    },
    num: {
      type: Number,
      required: true
    }
  },
  data() {
    return {};
  },

  created() {
    // console.log("num",this.num)
  },

  methods: {
    dship(flag) {
      let routerJump = this.$router.resolve({
        path: "/xuerenlist",
        query: { id: flag },
      });
      window.open(routerJump.href,'_blank')
    },
  },
};
</script>

<style scoped lang="scss">
.XueRenHeadline {
  margin: 1rem 0 0.5rem 0;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .XueRenHeadline_img{
    padding: 0 1.25rem;
    height: 4.5rem;
    cursor: pointer;
  }
  .left{
    width: 30rem;
  }
  .right{
    width: 30rem;
  }
}

</style>