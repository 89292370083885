<template>
  <div class="xueren">
    <div class="mainWidth">
      <!-- 共和国学人 -->
      <div class="main">
        <el-row type="flex" justify="center">
          <el-col>
            <Headline :title="require('@/assets/image/gonghe.png')" :num="86" />
            <XueRen1 :list="list1"></XueRen1>
          </el-col>
        </el-row>
      </div>
      <!-- 印象 -->
      <div class="botss">
        <el-row type="flex" justify="center">
          <el-col>
            <Headline :title="require('@/assets/image/yin.png')" :num="72" />

            <XueRen2 :list2="list2"></XueRen2>
          </el-col>
        </el-row>
      </div>
      <!-- 学术百家 -->
      <div class="fam">
        <el-row type="flex" justify="center">
          <el-col>
            <Headline :title="require('@/assets/image/family.png')" :num="87" />
            <XueRen3 :list="list3"></XueRen3>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
  
<script>
import NavType from "@/components/NavType.vue";
import api from "@/api/index.js";
import Headline from "./XueRenHeadline.vue";
import XueRen1 from "../../components/xueren/XueRen1.vue";
import XueRen2 from "../../components/xueren/XueRen2.vue";
import XueRen3 from "../../components/xueren/XueRen3.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    NavType,
    Headline,
    XueRen1,
    XueRen2,
    XueRen3,
  },
  props: {},
  data() {
    //这里存放数据
    return {
      xue_to: [],
      xue_th: [],
      all_list: [],
      itemIndex: 0,
      swiperOption: {
        slidesPerGroup:
          this.$store.state.isEquipment == "phone"
            ? 1
            : this.$store.state.smallPc
            ? 3
            : 4,
        initialSlide: 0,
        loop: true,
        paginationClickable: true,
        EffectCards: true,
        slidesPerView:
          this.$store.state.isEquipment == "phone"
            ? 1
            : this.$store.state.smallPc
            ? 3
            : 4,
        // spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // 底部轮播图
      swipertwo: {
        slidesPerGroup:
          this.$store.state.isEquipment == "phone"
            ? 1
            : this.$store.state.smallPc
            ? 3
            : 4,
        initialSlide: 0,
        loop: true,
        paginationClickable: true,
        EffectCards: true,
        slidesPerView:
          this.$store.state.isEquipment == "phone"
            ? 1
            : this.$store.state.smallPc
            ? 3
            : 4,
        spaceBetween: 17,
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
      },
      list1: [],
      list2: [],
      list3: [],
    };
  },
  //计算属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    btnClick(index) {
      this.itemIndex = index;
      console.log(this.itemIndex);
    },
    dship(flag) {
      let routerJump = this.$router.resolve({
        path: "/guorenlist",
        query: { xue_ren: flag },
      });
      window.open(routerJump.href, "_blank");
    },
    getList1() {
      return api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: 70,
        })
        .then((res) => {
          this.list1 = res.result;
        });
    },
    getList2() {
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: 72,
        })
        .then((res) => {
          this.list2 = res.result;
        });
    },
    getList3() {
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: 71, //63
        })
        .then((res) => {
          this.list3 = res.result;
        });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList1();
    this.getList2();
    this.getList3();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang="scss" scoped>
.xueren {
  background: #f6f6f6;
  padding-bottom: 1rem;
}

.textlay {
  padding-top: 1.25rem;
  width: 90%;
}

.Repulic {
  background-color: #f9f9f9;
}

.main {
  padding-top: 1.125rem;
  width: 100%;
  height: 817px;
}
@media screen and(max-width:1400px) {
  .main {
    height: 702px;
  }
}
.phone .main {
  height: auto;
  min-height: 500px;
}

.textlay {
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lebgf {
  display: inline-block;
  width: 30rem;
  height: 4.375rem;
  background: url(http://wx.xyxacs.com/images/titleLeft.png) right no-repeat;
}

.interview {
  width: 9.875rem;
  height: 4.375rem;
  padding: 0 2.5rem;
}

.rightbg {
  display: inline-block;
  width: 30rem;
  height: 4.375rem;
  background: url(http://wx.xyxacs.com/images/titleRight.png) left no-repeat;
}

.kamain {
  margin: 0.5rem auto;

  .four_x {
    margin-left: 2.5rem;
  }

  .crad {
    padding-bottom: 1rem;
  }

  .crad_content {
    // height: 31.875rem;
    // width: 270px;
    // height: 100%;
    width: 96%;
    box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.07);
    padding: 1rem;
    box-sizing: border-box;

    img {
      width: 100%;
      // height:auto;
      // aspect-ratio: 3/4;
    }

    .oop {
      font-size: 1rem;
      line-height: 1.75rem;
      height: 3.5rem;
      margin-top: 0.5rem;
      color: #000;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  .boxt_tou_1 {
    background: url(../../assets/image/bluebei.png) no-repeat;
    background-size: cover;
    width: 100%;
    height: 13.4375rem;
    display: flex;
    justify-content: center;

    .boxt_tou {
      width: 9.0625rem;
      height: 9.0625rem;
      margin-top: 2.8125rem;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  .card_text {
    padding: 0 1rem;
  }

  .kaming {
    font-size: 1.5rem;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000033;
    line-height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .degree {
    margin-top: 1rem;
    padding: 0 0.625rem;

    p {
      text-align: center;
      height: 1.6875rem;
      font-size: 1.125rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #666666;
      line-height: 1.6875rem;
      display: -webkit-box;
      overflow: hidden;
      white-space: normal !important;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .brief {
    font-size: 1rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 1.75rem;
    margin-top: 1rem;
    display: -webkit-box;
    overflow: hidden;
    white-space: normal !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
}

.arrowhead {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 1rem 0;
  --swiper-navigation-size: 0;

  img {
    width: 2rem;
    height: 2rem;
    position: static;
    margin: 0 0.4rem;
  }
}

.fam {
  background: url(../../assets/image/bigbg.png) no-repeat;
  background-size: 100% 100%;
  background-position: left bottom;

  .fam_top:first-child {
    margin-bottom: 2.5rem;
  }

  .fam_otf {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // margin-top: 1rem;
    align-content: space-between;
  }

  justify-content: space-between;

  .top_left {
    width: 49%;
    background: url("../../assets/image/kabei.png");
    background-size: 100% 100%;
    padding: 1rem 2rem;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 1rem;

    .top_toul {
      width: 10.5rem;
      height: 100%;
      float: left;

      .touxiang {
        width: 10.5rem;
        height: 10.5rem;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }

    .top_tour {
      margin-left: 12.375rem;
      padding: 1.5rem 1.5rem 1.5rem 0;

      .p1 {
        font-size: 1.25rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #212121;
        line-height: 1.25rem;
      }

      .danren {
        margin-top: 1rem;
        font-size: 1rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 1.5625rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      .p2 {
        margin-top: 0.9375rem;
        // width: 206px;
        // height: 21px;
        font-size: 1.25rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #212121;
        line-height: 1.25rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      .appraise {
        margin-top: 1rem;
        font-size: 1rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        line-height: 1.8125rem;

        .particulars {
          color: #287498;
          font-size: 1rem;
          cursor: pointer;
        }
      }
    }
  }
}

.arrowhead {
  .dian:nth-child(2) {
    margin-left: 0.9625rem;
  }

  .dian:nth-child(3) {
    margin-left: 0.9625rem;
  }

  display: flex;

  .dian {
    width: 0.9375rem;
    height: 0.9375rem;
    background: #6b97b6;
    opacity: 0.3;
    border-radius: 50%;
    cursor: pointer;
  }

  .D_duck {
    background-color: #1e3a52;
  }
}

.botss {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  margin-top: 2.5rem;
}

.box_t {
  a {
    color: #000033;
  }

  position: relative;
  margin-top: 1rem;
}

.arrows {
  position: absolute;
  top: 50%;
  left: -0.625rem;
  width: 100%;

  img {
    width: 2rem;
    // height: 3.125rem;
    cursor: pointer;
    z-index: 999;
  }

  .swipe_l {
    float: left;
    margin-left: -4.0625rem;
  }

  .swipe_r {
    float: right;
    margin-right: -5.3125rem;
  }
}

.smil_s {
  padding: 0.875rem 1rem 1rem 1rem;
  box-sizing: border-box;
  background-color: #ffffff;
  cursor: pointer;

  .Image {
    // width: 100%;
    // height: 17.5rem;

    // background-color: #000;
    img {
      width: 100%;
      height: 10.17rem;
    }
  }

  .Spacez {
    padding: 1rem 0 0 0;
    position: relative;

    .jaintou {
      width: 0.5625rem;
      height: 0.5625rem;
      position: absolute;
      top: 1.7rem;
      left: 0;
      // margin: 10px 11px;
    }

    .im_rigzi {
      text-align: left;
      margin-left: 1.125rem;
      color: #000000 !important;

      a {
        color: #000000 !important;
      }

      .title {
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 2rem;
      }

      .rimg_bot {
        // width: 241px;
        margin-top: 0.5rem;
        font-size: 1rem;
        line-height: 2rem;
        display: -webkit-box;
        overflow: hidden;
        white-space: normal !important;
        text-overflow: ellipsis;
        word-wrap: break-word;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.botss .smil_s:hover {
  background: #546092;
  color: #ffffff;
}

.crad_content {
  width: 100%;
  height: 100%;
  // background-color: red;
  box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.07);
  padding: 0.9375rem 0.9375rem 0.5rem 0.9375rem;
  box-sizing: border-box;

  img {
    width: 100%;
    // height:auto;
    height: 11.625rem;
    // aspect-ratio: 3/4;
  }

  .oop {
    font-size: 1.125rem;
    line-height: 1.75rem;
    height: 3.5rem;
    margin-top: 0.1875rem;
    color: #000000;
  }
}
</style>